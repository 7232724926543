import { makeStyles } from "@material-ui/styles";



const useStyles = makeStyles((theme)=>{
    return {
        home: {
            width: '100%', height: '100%',
            backgroundImage: 'linear-gradient(rgba(28,29,32,0.33),rgba(28,29,32,0.38),rgba(28,29,32,0.67),#1c1d20),url(/img/background-img.jpg)',
            backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed',
            padding: '1.8rem 1.9rem',
            position: 'relative',

            [theme.breakpoints.down('lg')]: {
                padding: '1.5rem 1.2rem',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '1.5rem 1rem',
            },
        },
        nav: {
            width: '100%',
        },
        logo: {
            width: '130px', height: '34px',
            marginTop: '-0.6rem',

            [theme.breakpoints.up('lgg')]:{
                width: '180px', height: '38px',
            }
        },
        menu: {
            position: 'absolute',
            top: '1.8rem', right: '1.9rem',
            color: '#fff',
            display:'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',

            [theme.breakpoints.up('lgg')]:{
                top: '2.8rem', right: '3.8rem',
            }
        },
        menuSpan: {
            display: 'none',

            [theme.breakpoints.up('mdd')]:{
                display: 'block',
                fontFamily: "'Poppins', sans-serif", fontSize: '1rem', fontWeight: '400', 
                textTransform: 'uppercase',
                letterSpacing: '2px',
            }
        },
        menuHolder: {
            // padding: '0.2rem 0.2rem 0rem 0.2rem',
            // backgroundColor: '#000',
            color: '#fff',
            marginLeft: '1rem',
            // borderRadius: '3px'
        },




        mainSection: {
            width: '70%',
            margin: '4rem auto',

            [theme.breakpoints.down('md')]:{
                width: '100%',
                margin: '3rem auto',
            }
        },
        imgHero: {
            width: '100%',
            height: '450px',
            position: 'relative',
            borderRadius: '10px',

            [theme.breakpoints.down('md')]:{
                height: '250px',
            }
        },
        imgHeroOverlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            background: '#1c1d2069',
            borderRadius: '10px'
        },
        blogTitleH1: {
            fontSize: '2.6rem',
            color: '#fff',
            marginBottom: '1rem',

            [theme.breakpoints.down('md')]:{
                fontSize: '1.9rem',
            }
        },
        blogPersonHolder: {
            display: 'flex',
            color: '#ccc'
        },
        blogPersonAvi: {
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            marginRight: '1rem'
        },

        paragraphTtile: {
            color: '#eee',
            marginBottom: '0.7rem',
            fontSize: '1.5rem',
            fontWeight: '700',

            [theme.breakpoints.down('md')]:{
                fontSize: '1.4rem',
            }
        },
        paragraph: {
            color: '#ccc',
            marginBottom: '4rem',
            fontSize: '1.1rem',
            lineHeight: '1.4',
            letterSpacing: '0.2px',

            [theme.breakpoints.down('md')]:{
                fontSize: '1rem',
                marginBottom: '2.9rem',
            }
        },

        iconInHero: {
            width: '20%', position: 'absolute', bottom: '5px', right: '5px',

            [theme.breakpoints.down('md')]:{
                width: '35%',
            }
        },
        titleHolder: {
            borderBottom: '1px solid #666', paddingBottom: '3rem', paddingTop: '1rem', marginBottom: '1rem',

            [theme.breakpoints.down('md')]:{
                paddingBottom: '2rem',
            }
        }
    }
})

export {useStyles}