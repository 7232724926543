import React from 'react'
import useStyles from '../../styles/component-styles/footer'
import { useTranslation } from 'react-i18next';

export default function Footer({ drawerActive,closeDrawer }){
    const [t, i18n] = useTranslation("global")
    const classes = useStyles()

    return(
        <section className={classes.section} onClick={drawerActive ? closeDrawer : null}>
            <div className={classes.mainFooter} style={{marginBottom: '2rem'}}>

                <div style={{marginBottom: '1rem'}}>
                    {/* <a target='_blank' href='https://discord.com/invite/ztStFzRSkR' className={classes.link}>Discord</a> */}
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/`} className={classes.link}>Home</a>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/market-place`} className={classes.link}>Store</a>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/support`} className={classes.link}>Support</a>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/frequently-asked-question`} className={classes.link}>FAQ</a>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/payment-tracker`} className={classes.link}>Payment Tracker</a>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/terms-of-service`} className={classes.link}>Terms Of Service</a>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/return-policy`} className={classes.link}>Refund Policy</a>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/privacy-policy`} className={classes.link}>Privacy Policy</a>
                </div>
            
            
                <div style={{marginBottom: '1rem'}}>
                    <div className={classes.legalImgHolder}>
                        <div className={classes.legalImg}>
                            <img src="/img/cert1.png" alt="" style={{width: '80px'}}/>
                        </div>
                        <div className={classes.legalImg}>
                            <img src="/img/cert2.jpg" alt="" style={{width: '70px'}}/>
                        </div>
                    </div>
                </div>
                <div>
                    <p className={classes.p}>© 2020 - 2024, Star Gaming Store. All rights reserved.</p>
                </div>

            </div>
        </section>
    )
}