import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import axios from 'axios'


const useStyles = makeStyles((theme)=>{
    return {
        main: {
            position:'fixed', 
            top: '0',
            left: '0',
            background: '#000000d1',
            width: "100vw",
            height: "100vh",
            display: 'flex',
            alignItems: 'center',
            justifyContent: "center",
            zIndex: '1000'
        },
        section: {
            background: '#eee',
            borderRadius: '10px',
            padding: '1.5rem',
            width: '95%',
            maxWidth: '400px',
        },
        input: {
            width: "100%",
            margin: '0rem auto',
            padding: '0.5rem',
            fontSize: '1rem',
            border: '2px solid #999',

            "&:focus": {
                border: '2px solid #ff3a3a',
                outline: "none"
            }
        },

        form: {
            margin: '1rem 0',
            display: 'flex',
            flexDirection: 'column',

        },

        button: {
            background: '#ff3a3a',
            padding: '0.5rem 0',
            fontSize: '1.1rem',
            color: '#fff',
            border: 'none',
            marginTop: '0.5rem',
            textTransform: 'uppercase'
        },
        overlay: {
            display: 'none'
        }
    }
})






export default function GetMailPopup({  }){
    const classes = useStyles()


    useEffect(()=>{
       const session = JSON.parse(localStorage.getItem('session')) 

       if(session){
            return
       }else{
            const timeoutId = setTimeout(() => {
                document.getElementById('mail-post-overlay').style.display = 'block' 
            }, 3 * 60 * 1000); // 4 minutes in milliseconds
        
            // Cleanup function to clear the timeout when the component unmounts
            return () => clearTimeout(timeoutId);
       }
    }, [])


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const handleSubmit =(e)=>{
        e.preventDefault()
        document.getElementById('mail-post-overlay').style.display = 'none'
        localStorage.setItem('session', JSON.stringify(true))

        const newFormData = new FormData
        newFormData.append('name', name)
        newFormData.append('email', email)
    
        axios.post(process.env.REACT_APP_MAIL_LIST_SUB, newFormData)
        .catch(error => {
            console.log(error)
        });
    }
    
    return (
        <div id='mail-post-overlay' className={classes.overlay}>

            <main className={classes.main}>

                
                <section className={classes.section}>
                    <div style={{width: '150px', margin: '0 auto', marginBottom: '1rem'}}>
                        <img src='/img/logo-dark.png' alt='logo' style={{width: '100%'}}/>
                    </div>
                    <div>
                        <p>Join our community & stay in the loop! Get updates & win surprises. Subscribe now to continue browsing this site!</p>
                    </div>

                    <form onSubmit={handleSubmit} className={classes.form}>
                        <input type='text' placeholder='Full Name' className={classes.input} style={{marginBottom: '0.5rem'}} onChange={(e)=> setName(e.currentTarget.value)} required/>
                        <input type='email' placeholder='Email' className={classes.input} style={{marginBottom: '0.5rem'}}  onChange={(e)=> setEmail(e.currentTarget.value)} required/>
                        <button className={classes.button}>submit</button>
                    </form>
                </section>


            </main>

        </div>
        

    )
}