import React, { useState, useEffect } from 'react';
import {useStyles} from '../../styles/page-styles/blogPost';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { GoTriangleDown } from "react-icons/go";
import { IoGlobeOutline } from "react-icons/io5";


export default function BlogPost5({ setDrawerActive }){

    useEffect(() => {
        document.title = 'Star Gaming Store Blog Post';
        window.scrollTo({ top: 0 })
    }, []);



    const classes = useStyles()
    const [t, i18n] = useTranslation("global")
    
    const openDrawer = () =>{
        setDrawerActive(true)
    }
   
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
          if(window.scrollY > 250){
            setScrolled(true)
          }else{
            setScrolled(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return(
        <main>
            <section id='home' className={classes.home}>
                
                <nav className={classes.nav}>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/`} style={{display: 'block', width: 'fit-content', position: 'relative', zIndex: '10'}}>
                        <div className={classes.logo}>
                            <img src="/img/logo.png" alt="logo" style={{width: '100%'}}/>
                        </div>
                    </a>

                    
                    {/* <div className='navRightSide'>

                        <div id='dropdown3' className='langugeSection'>
                            <IoGlobeOutline />
                            <h3 className='langugeText' >{activeLang}</h3>
                            <div><GoTriangleDown /></div>

                            <div id='dropdown-content3'>
                                <a onClick={()=> setActiveLang('en')}>EN</a>
                                <a onClick={()=> setActiveLang('es')}>ES</a>
                            </div>
                        </div>


                        <div id='menuholder' className={scrolled ? 'menu2' : 'menu'} onClick={openDrawer} style={{cursor: 'pointer'}}>


                            <span id='menu' className={scrolled ? 'menuSpan2' : 'menuSpan'}>{t("sidebar.menu")}</span>
                            <div id='menuIcon' className={scrolled ? 'menuHolder2' : 'menuHolder'}>
                                <MenuIcon style={{fontSize: '2rem'}}/>
                            </div>
                        </div>
                    </div> */}
        

                </nav>


                {/* <div className={classes.titleHolder}>
                    <h1 className={classes.h1}>{t("faq.pagetitle")}</h1>
                    <div></div>
                    <span className={classes.p}>{t("faq.subtitle")}</span>
                </div> */}


                <section className={classes.mainSection}>
                    <div className={classes.imgHero}>
                        <img src='/img/blog-heros/blog3.jpg' style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(0px)', borderRadius: '10px'}}/>
                        <div className={classes.imgHeroOverlay}>
                            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                <img src="/img/logo.png" alt="logo" className={classes.iconInHero}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.titleHolder}>

                        <span style={{color: '#ccc', fontSize: '600', lineHeight: '1.4'}}>14th June, 2020</span>


                        <h1 className={classes.blogTitleH1}>COD Stacked Accounts</h1>

                        <div className={classes.blogPersonHolder}>
                            <div className={classes.blogPersonAvi}>
                                <img src='/img/clients/blog1.jpg' style={{width: '100%', objectFit: 'contain', borderRadius: '50%'}}/>
                            </div>
                            <div>
                                <h4 style={{lineHeight: '1'}}>Thomas Benoit</h4>
                                <span style={{fontSize: '0.7rem', lineHeight: '1'}}>Blogger</span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <p className={classes.paragraph}>Welcome to Prime Shopping Centre, where gaming meets convenience like never before. Experience the thrill of gaming with our exclusive Call of Duty on Demand (COD) service, where you can purchase your favorite titles right here, right now.</p>

                        <p className={classes.paragraph}>Dive into the action-packed world of Call of Duty without delay! No need to wait for deliveries or worry about downloads. Simply select your desired game, make your purchase, and start playing instantly. </p>

                        <p className={classes.paragraph}>Enjoy the latest releases and classic favorites alike, all at your fingertips. Whether you're a seasoned veteran or a newcomer to the franchise, our extensive library ensures there's something for everyone.</p>

                        <p className={classes.paragraph}>So why wait? Level up your gaming experience with COD on Demand at Prime Shopping Centre today. Visit us now and embark on your next gaming adventure with ease and convenience!</p>
                    </div>


                </section>

                
                
            </section>
        </main>
    )
}