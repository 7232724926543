import React, { useState, useEffect } from 'react';
import {useStyles} from '../../styles/page-styles/blogPost';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { GoTriangleDown } from "react-icons/go";
import { IoGlobeOutline } from "react-icons/io5";


export default function BlogPost2({ setDrawerActive }){

    useEffect(() => {
        document.title = 'Star Gaming Store Blog Post';
        window.scrollTo({ top: 0 })
    }, []);



    const classes = useStyles()
    const [t, i18n] = useTranslation("global")
    
    const openDrawer = () =>{
        setDrawerActive(true)
    }
   
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
          if(window.scrollY > 250){
            setScrolled(true)
          }else{
            setScrolled(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);



    return(
        <main>
            <section id='home' className={classes.home}>
                
                <nav className={classes.nav}>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/`} style={{display: 'block', width: 'fit-content', position: 'relative', zIndex: '10'}}>
                        <div className={classes.logo}>
                            <img src="/img/logo.png" alt="logo" style={{width: '100%'}}/>
                        </div>
                    </a>

                    
                    {/* <div className='navRightSide'>

                        <div id='dropdown3' className='langugeSection'>
                            <IoGlobeOutline />
                            <h3 className='langugeText' >{activeLang}</h3>
                            <div><GoTriangleDown /></div>

                            <div id='dropdown-content3'>
                                <a onClick={()=> setActiveLang('en')}>EN</a>
                                <a onClick={()=> setActiveLang('es')}>ES</a>
                            </div>
                        </div>


                        <div id='menuholder' className={scrolled ? 'menu2' : 'menu'} onClick={openDrawer} style={{cursor: 'pointer'}}>


                            <span id='menu' className={scrolled ? 'menuSpan2' : 'menuSpan'}>{t("sidebar.menu")}</span>
                            <div id='menuIcon' className={scrolled ? 'menuHolder2' : 'menuHolder'}>
                                <MenuIcon style={{fontSize: '2rem'}}/>
                            </div>
                        </div>
                    </div> */}
        

                </nav>


                {/* <div className={classes.titleHolder}>
                    <h1 className={classes.h1}>{t("faq.pagetitle")}</h1>
                    <div></div>
                    <span className={classes.p}>{t("faq.subtitle")}</span>
                </div> */}


                <section className={classes.mainSection}>
                    <div className={classes.imgHero}>
                        <img src='/img/blog-heros/blog2.png' style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(0px)', borderRadius: '10px'}}/>
                        <div className={classes.imgHeroOverlay}>
                            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                <img src="/img/logo.png" alt="logo" className={classes.iconInHero}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.titleHolder}>

                        <span style={{color: '#ccc', fontSize: '600', lineHeight: '1.4'}}>14th June, 2020</span>


                        <h1 className={classes.blogTitleH1}>APEX LEGEND</h1>

                        <div className={classes.blogPersonHolder}>
                            <div className={classes.blogPersonAvi}>
                                <img src='/img/clients/blog1.jpg' style={{width: '100%', objectFit: 'contain', borderRadius: '50%'}}/>
                            </div>
                            <div>
                                <h4 style={{lineHeight: '1'}}>Thomas Benoit</h4>
                                <span style={{fontSize: '0.7rem', lineHeight: '1'}}>Blogger</span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <p className={classes.paragraph}>Welcome to Prime Shopping Centre, where we redefine entertainment with our latest Apex Legends Gaming. </p>

                        <p className={classes.paragraph}>Step into the future of gaming at our Apex Legends Gaming Hub. Experience the thrill of battle royale like never before, right here in the heart of Prime Shopping Centre. Whether you're a seasoned gamer or just starting your journey, our gaming hub offers the ultimate adrenaline rush for all. </p>


                        <p className={classes.paragraph}>Immerse yourself in the action with top of the line gaming rigs, cutting-edge graphics, and immersive sound systems. Engage in epic battles alongside friends and foes alike, as you strive for victory in the world's most exhilarating gaming experience. </p>

                        <p className={classes.paragraph}>But that's not all, at Prime, we believe in delivering more than just a gaming experience. Enjoy exclusive perks and amenities, including VIP access, gaming tournaments, and special events. Plus, our expert staff is always on hand to assist with any gaming needs, ensuring every visit is nothing short of extraordinary. </p>

                        <p className={classes.paragraph}>Join us at Prime Shopping Centre and elevate your gaming experience to new heights with Apex Legends Gaming Hub. Get ready to unleash your inner legend and conquer the virtual battlefield like never before. Your adventure awaits!</p>

                    </div>


                </section>

                
                
            </section>
        </main>
    )
}