import React, { useState, useEffect } from 'react';
import {useStyles} from '../../styles/page-styles/blogPost';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { GoTriangleDown } from "react-icons/go";
import { IoGlobeOutline } from "react-icons/io5";


export default function BlogPost3({ setDrawerActive }){

    useEffect(() => {
        document.title = 'Star Gaming Store Blog Post';
        window.scrollTo({ top: 0 })
    }, []);



    const classes = useStyles()
    const [t, i18n] = useTranslation("global")
    
    const openDrawer = () =>{
        setDrawerActive(true)
    }
   
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
          if(window.scrollY > 250){
            setScrolled(true)
          }else{
            setScrolled(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);



    return(
        <main>
            <section id='home' className={classes.home}>
                
                <nav className={classes.nav}>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/`} style={{display: 'block', width: 'fit-content', position: 'relative', zIndex: '10'}}>
                        <div className={classes.logo}>
                            <img src="/img/logo.png" alt="logo" style={{width: '100%'}}/>
                        </div>
                    </a>

                    
                    {/* <div className='navRightSide'>

                        <div id='dropdown3' className='langugeSection'>
                            <IoGlobeOutline />
                            <h3 className='langugeText' >{activeLang}</h3>
                            <div><GoTriangleDown /></div>

                            <div id='dropdown-content3'>
                                <a onClick={()=> setActiveLang('en')}>EN</a>
                                <a onClick={()=> setActiveLang('es')}>ES</a>
                            </div>
                        </div>


                        <div id='menuholder' className={scrolled ? 'menu2' : 'menu'} onClick={openDrawer} style={{cursor: 'pointer'}}>


                            <span id='menu' className={scrolled ? 'menuSpan2' : 'menuSpan'}>{t("sidebar.menu")}</span>
                            <div id='menuIcon' className={scrolled ? 'menuHolder2' : 'menuHolder'}>
                                <MenuIcon style={{fontSize: '2rem'}}/>
                            </div>
                        </div>
                    </div> */}
        

                </nav>


                {/* <div className={classes.titleHolder}>
                    <h1 className={classes.h1}>{t("faq.pagetitle")}</h1>
                    <div></div>
                    <span className={classes.p}>{t("faq.subtitle")}</span>
                </div> */}


                <section className={classes.mainSection}>
                    <div className={classes.imgHero}>
                        <img src='/img/blog-heros/blog3.jpg' style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(0px)', borderRadius: '10px'}}/>
                        <div className={classes.imgHeroOverlay}>
                            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                <img src="/img/logo.png" alt="logo" className={classes.iconInHero}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.titleHolder}>

                        <span style={{color: '#ccc', fontSize: '600', lineHeight: '1.4'}}>3rd April, 2020</span>


                        <h1 className={classes.blogTitleH1}>UNLOCK ALL TOOL</h1>

                        <div className={classes.blogPersonHolder}>
                            <div className={classes.blogPersonAvi}>
                                <img src='/img/clients/blog2.jpg' style={{width: '100%', objectFit: 'contain', borderRadius: '50%'}}/>
                            </div>
                            <div>
                                <h4 style={{lineHeight: '1'}}>Nefri Martin</h4>
                                <span style={{fontSize: '0.7rem', lineHeight: '1'}}>Blogger</span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <p className={classes.paragraph}>Welcome to Prime Shopping Centre, where gaming enthusiasts unite for the ultimate gaming experience! Are you tired of grinding endlessly to unlock the tools and weapons you need to dominate your favorite games? Look no further! Our exclusive service, Unlock All Tools, is here to revolutionize your gaming journey. </p>

                        <p className={classes.paragraph}>Imagine stepping into the virtual realm armed with every weapon, ability, and customization option at your fingertips. No more waiting, no more frustration, just pure gaming bliss. Whether you're diving into intense battles, exploring vast open worlds, or conquering challenging levels, our Unlock All Tools service ensures you're fully equipped to tackle any gaming adventure. </p>

                        <p className={classes.paragraph}>At Prime Shopping Centre, we understand the importance of convenience and enjoyment in gaming. That's why our dedicated team of gaming experts is committed to providing you with a seamless and secure unlocking process. With just a few simple steps, you can unlock the power to unleash your full gaming potential. </p>

                        <p className={classes.paragraph}>Don't let tedious challenges hold you back. Elevate your gaming experience today with Prime Shopping Centre's Unlock All Tools service. Visit us now and embark on an epic gaming journey like never before!</p>

                    </div>


                </section>

                
                
            </section>
        </main>
    )
}