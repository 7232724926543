import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme)=>{
    return {
        main: {
            width: '100%', 
            height: '90vh', 
            textAlign: 'center', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            padding: '1rem'
        }, 
        p1: {
            color: '#fff', 
            fontSize: '1.2rem', 
            lineHeight: '1.3',

            [theme.breakpoints.up('mdd')]:{
                fontSize: '1.2rem',
                lineHeight: '1.3',
            },
            [theme.breakpoints.up('lgg')]:{
                fontSize: '1.4rem',
                lineHeight: '1.3',
            }
        },
        p2: {
            color: '#fff', 
            fontSize: '1.6rem',
            fontWeight: '700', 
            letterSpacing: '1px', 
            lineHeight: '1.3',

            [theme.breakpoints.up('mdd')]:{
                fontSize: '2.6rem',
                lineHeight: '1.3',
            },
            [theme.breakpoints.up('lgg')]:{
                fontSize: '3.2rem',
                lineHeight: '1.3',
            }
        },
        p3: {
            color: '#fff', 
            fontSize: '1.1rem',
            lineHeight: '1.3',

            [theme.breakpoints.up('mdd')]:{
                fontSize: '1.2rem',
                lineHeight: '1.3',
            },
            [theme.breakpoints.up('lgg')]:{
                fontSize: '1.2rem',
                lineHeight: '1.3',
            }
        }
    }
})


export default function Error404(){
    const classes = useStyles()

    useEffect(() => {
        document.title = 'Page Not Found | Star Gaming Store';
        window.scrollTo({ top: 0 })
    }, []);

    return (
        <main className={classes.main}>
            <p className={classes.p1}>404</p>
            <p className={classes.p2}>CONTENT NOT FOUND</p>
            <p className={classes.p3}>Looks like we can't find this content on our server</p>
        </main>
    )
}