import React, { useState, useEffect } from 'react'
import useStyles from '../../styles/component-styles/sidebar'
import { RiCloseFill } from 'react-icons/ri'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ productList, drawerActive, setDrawerActive }){
    const classes = useStyles({drawerActive})
    const [t, i18n] = useTranslation("global")

    const closeDrawer = () =>{
        setDrawerActive(false)
    }

 

/*
This function maps through product list
and group get product (game) in it own
seperate arrate
*/
    const [apexArray, setApexArray] = useState([])
    const [codArray, setCodArray] = useState([])
    const [gtaArray, setGtaArray] = useState([])
    const [fortniteArray, setFortniteArray] = useState([])
    const [r6Array, setR6Array] = useState([])
    const [pokemonArray, setPokemonArray] = useState([])

    const groupProducts =(anArray)=>{
        if(anArray){
            
            anArray.map((item)=> {
                item.gamelist.map((innerItem) =>{
                    if(innerItem === 'apex'){
                        setApexArray(prevApexArray => [...prevApexArray, item.product])
                    }else if (innerItem === 'gta'){
                        setGtaArray(prevGtaArray => [...prevGtaArray, item.product])
                    }else if (innerItem === 'cod'){
                        setCodArray(prevCodArray => [...prevCodArray, item.product])
                    }else if (innerItem === 'fortnite'){
                        setFortniteArray(prevForniteArray => [...prevForniteArray, item.product])
                    }else if (innerItem === 'r6'){
                        setR6Array(prevR6Array => [...prevR6Array, item.product])
                    }else if (innerItem === 'pokemon'){
                        setPokemonArray(prevPokemonArray => [...prevPokemonArray, item.product])
                    }
                })
            })
        }
    }
    useEffect(()=>{
        groupProducts(productList)
    }, [productList])

    return (
        <aside className={classes.aside}>
            <section className={classes.asideInner}>

                <nav className={classes.nav} onClick={()=> closeDrawer()} style={{cursor: 'pointer'}}>
                    <span className={classes.navSpan} style={{textTransform: "uppercase"}}>Star Gaming Store</span>
                    <div className={classes.navIconHolder}>
                        <RiCloseFill className={classes.navIcon}/>
                    </div>
                </nav>

                <div>

                    <Link to='/market-place' onClick={()=> closeDrawer()} id='links' className={classes.links}>
                        <span id='linksSpan' className={classes.linksSpan}>01.</span>
                        <p className={classes.linksP}>{t("sidebar.links.store")}</p>
                    </Link>
                    <Link to='/' onClick={()=> closeDrawer()} id='links' className={classes.links} style={{borderTop: '1px solid rgba(255, 255, 255, 0.04)'}}>
                        <span id='linksSpan' className={classes.linksSpan}>02.</span>
                        <p className={classes.linksP}>{t("sidebar.links.home")}</p>
                    </Link>
                    <Link to='/support' onClick={()=> closeDrawer()} id='links' className={classes.links}>
                        <span id='linksSpan' className={classes.linksSpan}>03.</span>
                        <p className={classes.linksP}>{t("sidebar.links.support")}</p>
                    </Link>
                    <Link to='/frequently-asked-question' onClick={()=> closeDrawer()} id='links' className={classes.links}>
                        <span id='linksSpan' className={classes.linksSpan}>04.</span>
                        <p className={classes.linksP}>{t("sidebar.links.faq")}</p>
                    </Link>
                    <Link to='/payment-tracker' onClick={()=> closeDrawer()} id='links' className={classes.links}>
                        <span id='linksSpan' className={classes.linksSpan}>05.</span>
                        <p className={classes.linksP}>{t("sidebar.links.paymentchecker")}</p>
                    </Link>
                    {/* <Link to='/reviews' onClick={()=> closeDrawer()} id='links' className={classes.links}>
                        <span id='linksSpan' className={classes.linksSpan}>06.</span>
                        <p className={classes.linksP}>{t("sidebar.links.reviews")}</p>
                    </Link> */}
                    

                    <div className={classes.gameLinksHolder}>
                        <span className={classes.navSpan}>{t("sidebar.title2")}</span>

                        <div className={classes.linksSecond}>

                            <div id='dropdown' style={{marginBottom: '1rem'}}>
                                <span className={classes.gamelinks}>GTA V</span>
                                <div id="dropdown-content2">
                                {
                                    gtaArray ?
                                        gtaArray.map((item)=> {
                                            return (
                                                item === 'Stacked Account' ? <a href={`/packages/grand-theft-auto/stacked-account`}>{item}</a> : 
                                                item === 'Account Boosting' ? <a href={`/packages/grand-theft-auto/account-boosting`}>{item}</a> : 
                                                item === 'Level/RP Boosting' ? <a href={`/packages/grand-theft-auto/level-rp-boosting`}>{item}</a> : 
                                                item === 'Mod Menu' ? <a href={`/packages/grand-theft-auto/mod-menu`}>{item}</a> : 
                                                item === 'Money Drop' ? <a href={`/packages/grand-theft-auto/money-drop`}>{item}</a> : 
                                                item === 'Unlock All Tools' ? <a href={`/packages/grand-theft-auto/unlock-all-tools`}>{item}</a> :
                                                null 
                                            )
                                        })                            
                                    : null
                                }
                                </div>
                            </div>

                            <div id='dropdown' style={{marginBottom: '1rem'}}>
                                <span className={classes.gamelinks}>COD</span>
                                <div id="dropdown-content2">
                                {
                                    codArray ?
                                        codArray.map((item)=> {
                                            return (
                                                item === 'Stacked Account' ? <a href={`/packages/call-of-duty/stacked-account`}>{item}</a> : 
                                                item === 'Account Boosting' ? <a href={`/packages/call-of-duty/account-boosting`}>{item}</a> : 
                                                item === 'Level/RP Boosting' ? <a href={`/packages/call-of-duty/level-rp-boosting`}>{item}</a> : 
                                                item === 'Mod Menu' ? <a href={`/packages/call-of-duty/mod-menu`}>{item}</a> : 
                                                item === 'Money Drop' ? <a href={`/packages/call-of-duty/money-drop`}>{item}</a> : 
                                                item === 'Unlock All Tools' ? <a href={`/packages/call-of-duty/unlock-all-tools`}>{item}</a> :
                                                null 
                                            )
                                        })                            
                                    : null
                                }
                                </div>
                            </div>

                            <div id='dropdown' style={{marginBottom: '1rem'}}>
                                <span className={classes.gamelinks}>Fortnite</span>
                                <div id="dropdown-content2">
                                {
                                    fortniteArray ?
                                        fortniteArray.map((item)=> {
                                            return (
                                                item === 'Stacked Account' ? <a href={`/packages/fortnite/stacked-account`}>{item}</a> : 
                                                item === 'Account Boosting' ? <a href={`/packages/fortnite/account-boosting`}>{item}</a> : 
                                                item === 'Level/RP Boosting' ? <a href={`/packages/fortnite/level-rp-boosting`}>{item}</a> : 
                                                item === 'Mod Menu' ? <a href={`/packages/fortnite/mod-menu`}>{item}</a> : 
                                                item === 'Money Drop' ? <a href={`/packages/fortnite/money-drop`}>{item}</a> : 
                                                item === 'Unlock All Tools' ? <a href={`/packages/fortnite/unlock-all-tools`}>{item}</a> :
                                                null 
                                            )
                                        })                            
                                    : null
                                }
                                </div>
                            </div>
                            
                            <div id='dropdown' style={{marginBottom: '1rem'}}>
                                <span className={classes.gamelinks}>Apex</span>
                                <div id="dropdown-content2">
                                {
                                    apexArray ?
                                        apexArray.map((item)=> {
                                            return (
                                                item === 'Stacked Account' ? <a href={`/packages/apex/stacked-account`}>{item}</a> : 
                                                item === 'Account Boosting' ? <a href={`/packages/apex/account-boosting`}>{item}</a> : 
                                                item === 'Level/RP Boosting' ? <a href={`/packages/apex/level-rp-boosting`}>{item}</a> : 
                                                item === 'Mod Menu' ? <a href={`/packages/apex/mod-menu`}>{item}</a> : 
                                                item === 'Money Drop' ? <a href={`/packages/apex/money-drop`}>{item}</a> : 
                                                item === 'Unlock All Tools' ? <a href={`/packages/apex/unlock-all-tools`}>{item}</a> :
                                                null 
                                            )
                                        })                            
                                    : null
                                }
                                </div>
                            </div>

                            <div id='dropdown' style={{marginBottom: '1rem'}}>
                                <span className={classes.gamelinks}>R6 Siege</span>
                                <div id="dropdown-content2">
                                {
                                    r6Array ?
                                        r6Array.map((item)=> {
                                            return (
                                                item === 'Stacked Account' ? <a href={`/packages/rainbow-six/stacked-account`}>{item}</a> : 
                                                item === 'Account Boosting' ? <a href={`/packages/rainbow-six/account-boosting`}>{item}</a> : 
                                                item === 'Level/RP Boosting' ? <a href={`/packages/rainbow-six/level-rp-boosting`}>{item}</a> : 
                                                item === 'Mod Menu' ? <a href={`/packages/rainbow-six/mod-menu`}>{item}</a> : 
                                                item === 'Money Drop' ? <a href={`/packages/rainbow-six/money-drop`}>{item}</a> : 
                                                item === 'Unlock All Tools' ? <a href={`/packages/rainbow-six/unlock-all-tools`}>{item}</a> :
                                                null 
                                            )
                                        })                            
                                    : null
                                }
                                </div>
                            </div>

                            <div id='dropdown' style={{marginBottom: '1rem'}}>
                                <span className={classes.gamelinks}>Pokémon Go</span>
                                <div id="dropdown-content2">
                                {
                                    pokemonArray ?
                                    pokemonArray.map((item)=> {
                                            return (
                                                item === 'Stacked Account' ? <a href={`/packages/pokemon-go/stacked-account`}>{item}</a> : 
                                                item === 'Account Boosting' ? <a href={`/packages/pokemon-go/account-boosting`}>{item}</a> : 
                                                item === 'Level/RP Boosting' ? <a href={`/packages/pokemon-go/level-rp-boosting`}>{item}</a> : 
                                                item === 'Mod Menu' ? <a href={`/packages/pokemon-go/mod-menu`}>{item}</a> : 
                                                item === 'Money Drop' ? <a href={`/packages/pokemon-go/money-drop`}>{item}</a> : 
                                                item === 'Unlock All Tools' ? <a href={`/packages/pokemon-go/unlock-all-tools`}>{item}</a> :
                                                null 
                                            )
                                        })                            
                                    : null
                                }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                
            </section>
        </aside>
    )
}