import React, { useState, useEffect } from 'react';
import {useStyles} from '../../styles/page-styles/blogPost';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { GoTriangleDown } from "react-icons/go";
import { IoGlobeOutline } from "react-icons/io5";


export default function BlogPost1({ setDrawerActive }){

    useEffect(() => {
        document.title = 'Star Gaming Store Blog Post';
        window.scrollTo({ top: 0 })
    }, []);



    const classes = useStyles()
    const [t, i18n] = useTranslation("global")
    
    const openDrawer = () =>{
        setDrawerActive(true)
    }
   
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
          if(window.scrollY > 250){
            setScrolled(true)
          }else{
            setScrolled(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return(
        <main>
            <section id='home' className={classes.home}>
                
                <nav className={classes.nav}>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/`} style={{display: 'block', width: 'fit-content', position: 'relative', zIndex: '10'}}>
                        <div className={classes.logo}>
                            <img src="/img/logo.png" alt="logo" style={{width: '100%'}}/>
                        </div>
                    </a>

                    
                    {/* <div className='navRightSide'>

                        <div id='dropdown3' className='langugeSection'>
                            <IoGlobeOutline />
                            <h3 className='langugeText' >{activeLang}</h3>
                            <div><GoTriangleDown /></div>

                            <div id='dropdown-content3'>
                                <a onClick={()=> setActiveLang('en')}>EN</a>
                                <a onClick={()=> setActiveLang('es')}>ES</a>
                            </div>
                        </div>


                        <div id='menuholder' className={scrolled ? 'menu2' : 'menu'} onClick={openDrawer} style={{cursor: 'pointer'}}>


                            <span id='menu' className={scrolled ? 'menuSpan2' : 'menuSpan'}>{t("sidebar.menu")}</span>
                            <div id='menuIcon' className={scrolled ? 'menuHolder2' : 'menuHolder'}>
                                <MenuIcon style={{fontSize: '2rem'}}/>
                            </div>
                        </div>
                    </div> */}
        

                </nav>


                <section className={classes.mainSection}>
                    <div className={classes.imgHero}>
                        <img src='/img/background-img.png' style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(0px)', borderRadius: '10px'}}/>
                        <div className={classes.imgHeroOverlay}>
                            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                <img src="/img/logo.png" alt="logo" className={classes.iconInHero}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.titleHolder}>

                        <span style={{color: '#ccc', fontSize: '600', lineHeight: '1.4'}}>3rd April, 2020</span>


                        <h1 className={classes.blogTitleH1}>Welcome to Star Gaming Store</h1>

                        <div className={classes.blogPersonHolder}>
                            <div className={classes.blogPersonAvi}>
                                <img src='/img/clients/blog2.jpg' style={{width: '100%', objectFit: 'contain', borderRadius: '50%'}}/>
                            </div>
                            <div>
                                <h4 style={{lineHeight: '1'}}>Nefri Martin</h4>
                                <span style={{fontSize: '0.7rem', lineHeight: '1'}}>Blogger</span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <h2 className={classes.paragraphTtile}>Welcome to Star Gaming Store: Your Premier Destination for Gaming Excellence.</h2>
                        <p className={classes.paragraph}>Are you ready to elevate your gaming experience to unprecedented levels? Discover Prime Shopping Centre, your ultimate gaming haven where you'll find everything you need to excel in the virtual realm.</p>

                        <h2 className={classes.paragraphTtile}>Unlock the Ultimate Gaming Experience </h2>
                        <p className={classes.paragraph}>Prime Shopping Centre offers a diverse range of tailored services to meet the unique needs of every gamer. Whether you're passionate about Apex Legends, GTA5, Fortnite, or Call of Duty, our stacked accounts, boost services, and more are designed to enhance your gameplay and take you to new heights. </p>


                        <h2 className={classes.paragraphTtile}>Enhance Your Gameplay with Stacked Accounts </h2>
                        <p className={classes.paragraph}>Gain a competitive advantage with our stacked accounts for popular titles like Apex Legends, GTA5, Fortnite, and COD. Equipped with pre-loaded characters, weapons, and in-game currency, you'll be primed for success from the get go. </p>



                        <h2 className={classes.paragraphTtile}>Level Up Your Skills with Boost Services  </h2>
                        <p className={classes.paragraph}>Ready to ascend the ranks and unlock exclusive rewards? Our boost services for Apex Legends and GTA5 provide a fast track to success, helping you level up rapidly and establish yourself as a gaming virtuoso. </p>



                        <h2 className={classes.paragraphTtile}>Unlock Tools and Mods for Ultimate Customization </h2>
                        <p className={classes.paragraph}>Personalize your gaming experience with our array of unlock tools and mods for GTA5. From mod menus to money drops and RP boosting, you'll have the flexibility to tailor the game to your preferences and unleash your creativity. </p>


                        <h2 className={classes.paragraphTtile}>Shop with Confidence  </h2>
                        <p className={classes.paragraph}>At Prime Shopping Centre, customer satisfaction is paramount. Rest assured knowing that all our products and services are of the highest quality and authenticity. With secure payment options and dependable customer support, you can shop with peace of mind. </p>


                        <h2 className={classes.paragraphTtile}>Start Dominating Today</h2>
                        <p className={classes.paragraph}>Ready to conquer the virtual world? Explore our extensive selection of stacked accounts, boost services, and unlock tools at Prime Shopping Centre and embark on your journey to gaming greatness. Your ultimate gaming experience awaits</p>
                    </div>


                </section>

                
                
            </section>
        </main>
    )
}