import React from 'react'
import Sidebar from './components/app-components/Sidebar'
import Footer from './components/app-components/Footer'
import Preloader from './Preloader'
import GetMailPopup from './components/app-components/GetMailPopup'



export default function Layout({  children, preload, productList, drawerActive, setDrawerActive }){
    const closeDrawer = () =>{
        setDrawerActive(false)
    }
    return (
        <>
        {

            preload
            ?
                <Preloader />
            :
                <main style={{position: 'relative'}}>
                    <Sidebar productList={productList} drawerActive={drawerActive} setDrawerActive={setDrawerActive}/>

                    <div onClick={drawerActive ? closeDrawer : null}>
                        {children}
                    </div>

                    <Footer drawerActive={drawerActive} closeDrawer={closeDrawer}/>


                </main>
        }
        <GetMailPopup />
        </>
    )
}