import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme)=>{
    return {
          aside: {
            position: 'fixed',
            top: '0', right: '0',
            width: '20%', height: '100vh',
            minWidth: '290px',
            backgroundColor: '#0c0c0c',
            zIndex: '100',
            fontFamily: "'Poppins', sans-serif",
            transition: 'all 0.3s linear',
            overflow: 'auto !important',
            transform: ({ drawerActive })=>{
                if(drawerActive === true){
                  return 'translate3d(-100, 0, 0)'
                }else{
                  return 'translate3d(100%, 0, 0)'
                }
            },

            '&::-webkit-scrollbar':{
              display: 'none'
            }
          },
          asideInner: {
            padding: '3rem 2.4rem',
            color: '#fff'
          },
          nav: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2.7rem'
          },
          navSpan:{
            fontFamily: "'Poppins', sans-serif", fontSize: '0.9rem',
            letterSpacing: '1.4px',
            color: '#ff3a3a',
          },
          navIconHolder: {
            width: '1.8rem', height: '1.8rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems:'center',
            backgroundColor: '#000',
            borderRadius: '2px',
          },
          navIcon: {
            color: '#fff !important',
            fontSize: '1.4rem !important',
            fontWeight: '700 !important'
          },
          links:{
            display: 'flex',
            textDecoration: 'none',
            borderBottom: '1px solid rgba(255, 255, 255, 0.04)',
            padding: '1rem 0',
            transition: 'all 0.3s ease-in-out',
          },
          linksP: {
            color: 'white',
            display: 'block',
            paddingLeft: '1.5rem',
            letterSpacing: '1px'
          },
          linksSpan: {
            color: '#3b3a39',
            fontSize: '0.9rem',
            transition: 'all 0.3s ease-in-out',
          },


          linksSecond: {
            display: 'grid',
            textAlign: 'left',
            textDecoration: 'none',
            padding: '2rem 0rem'
          },
          gamelinks: {
            display: 'inline-block',
            color: '#999999',
            fontSize: '1.2rem',
            letterSpacing: '1px',
            marginBottom: '0.5rem',
            transition: 'all 0.3s ease-in-out',
            cursor: 'default',

            '&:hover':{
                color: '#ffffff'
            }
          },
          gameLinksHolder: {
            padding: '2rem 0'
          }
    }
})


export default useStyles