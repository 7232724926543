import react, { useEffect, useState } from 'react'
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

import axios from 'axios';
import Error404 from './pages/Error404';
import BlogPost1 from './pages/blogs/BlogPost1';
import BlogPost5 from './pages/blogs/BlogPost5';
import BlogPost6 from './pages/blogs/BlogPost6';
import BlogPost7 from './pages/blogs/BlogPost7';
import BlogPost8 from './pages/blogs/BlogPost8';
import BlogPost2 from './pages/blogs/BlogPost2';
import BlogPost3 from './pages/blogs/BlogPost3';
import BlogPost4 from './pages/blogs/BlogPost4';



function App() {

/*
=============================================================== 
//* Define MUI Themes
===============================================================
*/
const theme = createTheme({
  palette: {
  
    primary:{
      main: '#ccc',
      backgroundColor: '#272727'
    },
    secondary: {
      main: "#fff"
    },
    neutral: {
      main: "#fc4445"
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      smm: 480,
      sm: 600,
      mdd: 668,
      md: 768,
      l: 900,
      lg: 940,
      lgg: 1300,
      xl: 1436,
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  appBoundaries: {
    sizeLight: '0.5rem',
    sizeLight2: '0.8rem',
    sizeRegular: '1rem',
    sizeRegular2: '1.5rem',
    sizeRegular3: '2rem',
    sizeMedium: '3rem',
    sizeBold: '3.5rem',
  },
  
})


const [drawerActive, setDrawerActive] = useState(false)
const [productList, setProductList] = useState([])
const [productLoading, setProdouctLoading] = useState(true)
const [productFail, setProdouctFile] = useState(false)


useEffect(()=>{
  setProdouctLoading(true)
  setProdouctFile(false)
  axios.get(process.env.REACT_APP_PRODUCT_ENDPOINT)
  .then(function (response) {
      // handle success
      setProdouctFile(false)
      setProductList(response.data)
      setProdouctLoading(false)
    })
    .catch(function (error) {
      // handle error
      setProdouctFile(true)
      setProdouctLoading(false)
    })
}, [])


/*
=============================================================== 
//* AOS iNIT
===============================================================
*/
useEffect(()=> {
  AOS.init({
    duration: 400,
    easing: 'ease-in-sine',
    delay: 0,
    once: true
  })
  AOS.refresh()

  return () => {
    AOS.refresh()
  }
}, [drawerActive, productList, productFail, productLoading])


/*
=============================================================== 
//* pre loader
===============================================================
*/
const [preload, setPreload] = useState(true)


useEffect(()=>{
  setPreload(false)
})

  return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Layout preload={preload} productList={productList} drawerActive={drawerActive} setDrawerActive={setDrawerActive}>
              <Routes>

                <Route exact path='/ac2236730fcfce0a' element={<BlogPost1 setDrawerActive={setDrawerActive}/>}/>
                <Route exact path='/ac2236730fcfce1b' element={<BlogPost2 setDrawerActive={setDrawerActive}/>}/>
                <Route exact path='/ac2236730fcfce2c' element={<BlogPost3 setDrawerActive={setDrawerActive}/>}/>
                <Route exact path='/ac2236730fcfce3d' element={<BlogPost4 setDrawerActive={setDrawerActive}/>}/>
                <Route exact path='/ac2236730fcfce4e' element={<BlogPost5 setDrawerActive={setDrawerActive}/>}/>
                <Route exact path='/ac2236730fcfce5f' element={<BlogPost6 setDrawerActive={setDrawerActive}/>}/>
                <Route exact path='/ac2236730fcfce6g' element={<BlogPost7 setDrawerActive={setDrawerActive}/>}/>
                <Route exact path='/ac2236730fcfce7i' element={<BlogPost8 setDrawerActive={setDrawerActive}/>}/>

                <Route exact path='*' element={<Error404 />}/>

              </Routes>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
  );
}
export default App;
