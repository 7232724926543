import React, { useState, useEffect } from 'react';
import {useStyles} from '../../styles/page-styles/blogPost';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { GoTriangleDown } from "react-icons/go";
import { IoGlobeOutline } from "react-icons/io5";


export default function BlogPost8({ setDrawerActive }){

    useEffect(() => {
        document.title = 'Star Gaming Store Blog Post';
        window.scrollTo({ top: 0 })
    }, []);



    const classes = useStyles()
    const [t, i18n] = useTranslation("global")
    
    const openDrawer = () =>{
        setDrawerActive(true)
    }
   
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
          if(window.scrollY > 250){
            setScrolled(true)
          }else{
            setScrolled(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return(
        <main>
            <section id='home' className={classes.home}>
                
                <nav className={classes.nav}>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/`} style={{display: 'block', width: 'fit-content', position: 'relative', zIndex: '10'}}>
                        <div className={classes.logo}>
                            <img src="/img/logo.png" alt="logo" style={{width: '100%'}}/>
                        </div>
                    </a>

                    
                    {/* <div className='navRightSide'>

                        <div id='dropdown3' className='langugeSection'>
                            <IoGlobeOutline />
                            <h3 className='langugeText' >{activeLang}</h3>
                            <div><GoTriangleDown /></div>

                            <div id='dropdown-content3'>
                                <a onClick={()=> setActiveLang('en')}>EN</a>
                                <a onClick={()=> setActiveLang('es')}>ES</a>
                            </div>
                        </div>


                        <div id='menuholder' className={scrolled ? 'menu2' : 'menu'} onClick={openDrawer} style={{cursor: 'pointer'}}>


                            <span id='menu' className={scrolled ? 'menuSpan2' : 'menuSpan'}>{t("sidebar.menu")}</span>
                            <div id='menuIcon' className={scrolled ? 'menuHolder2' : 'menuHolder'}>
                                <MenuIcon style={{fontSize: '2rem'}}/>
                            </div>
                        </div>
                    </div> */}
        

                </nav>


                {/* <div className={classes.titleHolder}>
                    <h1 className={classes.h1}>{t("faq.pagetitle")}</h1>
                    <div></div>
                    <span className={classes.p}>{t("faq.subtitle")}</span>
                </div> */}


                <section className={classes.mainSection}>
                    <div className={classes.imgHero}>
                        <img src='/img/blog-heros/blog4.jpg' style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(0px)', borderRadius: '10px'}}/>
                        <div className={classes.imgHeroOverlay}>
                            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                <img src="/img/logo.png" alt="logo" className={classes.iconInHero}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.titleHolder}>

                        <span style={{color: '#ccc', fontSize: '600', lineHeight: '1.4'}}>3rd April, 2020</span>


                        <h1 className={classes.blogTitleH1}>LEVEL/RP BOOSTING</h1>

                        <div className={classes.blogPersonHolder}>
                            <div className={classes.blogPersonAvi}>
                                <img src='/img/clients/blog2.jpg' style={{width: '100%', objectFit: 'contain', borderRadius: '50%'}}/>
                            </div>
                            <div>
                                <h4 style={{lineHeight: '1'}}>Nefri Martin</h4>
                                <span style={{fontSize: '0.7rem', lineHeight: '1'}}>Blogger</span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <p className={classes.paragraph}>Welcome to Prime Shopping Centre - Your Gateway to Gaming Excellence!</p>

                        <p className={classes.paragraph}>Are you looking to elevate your gaming experience to the next level? Look no further! At Prime Shopping Centre, we're thrilled to introduce our exclusive Level Boosting Service tailored just for gamers like you.</p>

                        <p className={classes.paragraph}>Say goodbye to endless hours of gameplay and hello to rapid progression! Our Level Boosting Service will catapult your character to new heights, unlocking exciting content and rewards in no time.</p>

                        <p className={classes.paragraph}>Stay ahead of the curve and leave your rivals in the dust. With our expertly crafted level-boosting techniques, you'll emerge as a formidable force in the gaming arena, ready to conquer any challenge that comes your way.</p>

                        <p className={classes.paragraph}>Our seamless integration ensures a stress free experience every step of the way. Simply select your game, choose your desired level, and watch as your gaming prowess reaches new heights all within the comfort of Prime Shopping Centre. </p>

                        <p className={classes.paragraph}>Whether you're a seasoned gamer seeking new thrills or a newcomer eager to explore uncharted territories, our Level Boosting Service is your ticket to an unforgettable gaming journey. Unlock hidden achievements, discover secret treasures, and embark on epic quests like never before.</p>

                        <p className={classes.paragraph}>Visit us today and embark on the ultimate gaming adventure at Prime Shopping Centre – where excellence meets excitement, and every level is within reach!</p>
                    </div>


                </section>

                
                
            </section>
        </main>
    )
}