import React from 'react'
import { Bars } from 'react-loader-spinner'


export default function Preloader(){
    return (
        <section style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center',alignItems: 'center'}}>
            <Bars
                height="80"
                width="80"
                color="#ff3a3a"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </section>
    )
}