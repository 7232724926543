import React, { useState, useEffect } from 'react';
import {useStyles} from '../../styles/page-styles/blogPost';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { GoTriangleDown } from "react-icons/go";
import { IoGlobeOutline } from "react-icons/io5";


export default function BlogPost7({ setDrawerActive }){

    useEffect(() => {
        document.title = 'Star Gaming Store Blog Post';
        window.scrollTo({ top: 0 })
    }, []);



    const classes = useStyles()
    const [t, i18n] = useTranslation("global")
    
    const openDrawer = () =>{
        setDrawerActive(true)
    }
   
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
          if(window.scrollY > 250){
            setScrolled(true)
          }else{
            setScrolled(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return(
        <main>
            <section id='home' className={classes.home}>
                
                <nav className={classes.nav}>
                    <a href={`${process.env.REACT_APP_DOMAIN_NAME}/`} style={{display: 'block', width: 'fit-content', position: 'relative', zIndex: '10'}}>
                        <div className={classes.logo}>
                            <img src="/img/logo.png" alt="logo" style={{width: '100%'}}/>
                        </div>
                    </a>

                    
                    {/* <div className='navRightSide'>

                        <div id='dropdown3' className='langugeSection'>
                            <IoGlobeOutline />
                            <h3 className='langugeText' >{activeLang}</h3>
                            <div><GoTriangleDown /></div>

                            <div id='dropdown-content3'>
                                <a onClick={()=> setActiveLang('en')}>EN</a>
                                <a onClick={()=> setActiveLang('es')}>ES</a>
                            </div>
                        </div>


                        <div id='menuholder' className={scrolled ? 'menu2' : 'menu'} onClick={openDrawer} style={{cursor: 'pointer'}}>


                            <span id='menu' className={scrolled ? 'menuSpan2' : 'menuSpan'}>{t("sidebar.menu")}</span>
                            <div id='menuIcon' className={scrolled ? 'menuHolder2' : 'menuHolder'}>
                                <MenuIcon style={{fontSize: '2rem'}}/>
                            </div>
                        </div>
                    </div> */}
        

                </nav>


                {/* <div className={classes.titleHolder}>
                    <h1 className={classes.h1}>{t("faq.pagetitle")}</h1>
                    <div></div>
                    <span className={classes.p}>{t("faq.subtitle")}</span>
                </div> */}


                <section className={classes.mainSection}>
                    <div className={classes.imgHero}>
                        <img src='/img/blog-heros/blog5.jpg' style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(0px)', borderRadius: '10px'}}/>
                        <div className={classes.imgHeroOverlay}>
                            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                <img src="/img/logo.png" alt="logo" className={classes.iconInHero}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.titleHolder}>

                        <span style={{color: '#ccc', fontSize: '600', lineHeight: '1.4'}}>14th June, 2020</span>


                        <h1 className={classes.blogTitleH1}>FORTNITE</h1>

                        <div className={classes.blogPersonHolder}>
                            <div className={classes.blogPersonAvi}>
                                <img src='/img/clients/blog1.jpg' style={{width: '100%', objectFit: 'contain', borderRadius: '50%'}}/>
                            </div>
                            <div>
                                <h4 style={{lineHeight: '1'}}>Thomas Benoit</h4>
                                <span style={{fontSize: '0.7rem', lineHeight: '1'}}>Blogger</span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <p className={classes.paragraph}>Step into the world of Prime Shopping Centre, where luxury meets entertainment, and gaming takes center stage. We're thrilled to introduce a groundbreaking addition to our lineup of services: Fortnite Gaming Lounge.</p>

                        <p className={classes.paragraph}>Prepare to be transported to the thrilling universe of Fortnite, right here in the heart of our shopping center. Whether you're a seasoned veteran or a newcomer to the game, our gaming lounge offers an unparalleled experience for players of all skill levels.</p>

                        <p className={classes.paragraph}>Immerse yourself in the action as you drop into the island and compete against friends and fellow shoppers in epic battles for victory royale. With high-end gaming PCs, cutting-edge consoles, and immersive surround sound systems, every match is an adrenaline-fueled adventure that will keep you on the edge of your seat.</p>

                        <p className={classes.paragraph}>But the Fortnite Gaming Lounge is more than just a place to play it's a social hub where gamers can come together to connect, compete, and celebrate their shared passion for gaming. Meet new friends, form squads, and forge alliances as you embark on epic quests and conquer the island together. </p>

                        <p className={classes.paragraph}>And with regular events, tournaments, and exclusive giveaways, there's always something new and exciting happening at the Fortnite Gaming Lounge. Whether you're aiming for the top spot in a competitive tournament or just looking to unwind with a few rounds of casual gameplay, our gaming lounge has something for everyone.</p>

                        <p className={classes.paragraph}>So why wait? Join us at Prime Shopping Centre and experience the thrill of Fortnite like never before. Whether you're a hardcore gamer or just looking for a fun way to spend an afternoon, the Fortnite Gaming Lounge is your ultimate destination for gaming excitement. See you on the island! </p>
                    </div>


                </section>

                
                
            </section>
        </main>
    )
}