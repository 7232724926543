import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme)=>{
    return {
        section: {
            width: '100%',
            
            padding: '2rem 2rem',
            borderTop: '1px solid #555',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: "'Poppins', sans-serif",
        },
        mainFooter: {
            display: 'flex',
            flexDirection:'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        link: {
            display: 'block',
            textAlign: 'center',
            color: '#fff',
            textDecoration:'none',
            margin: '0.5rem 0.7rem',
            fontFamily: "'Poppins', sans-serif",
            whiteSpace: 'nowrap',
            transition: 'all 0.3s linear',
            "&:hover":{
                color: '#ff3a3a'
            },

            [theme.breakpoints.up('lg')]:{
                display: 'inline',
            }

        },
        img: {
            width: '250px',
        },
        p: {
            color: '#999999'
        },
        legalImgHolder: {
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

        },
        legalImg: {
            margin: '0rem 0.5rem'
        },
    }
})

export default useStyles